import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  isChanged: false,
  adminAuth: {}
};

export const adminAuthSlice = createSlice({
  name: "adminAuth",
  initialState,
  reducers: {
    adminAuthToken: (state, action) => {
        state.adminAuth = action.payload
      },
    setIsAuthenticated: (state, action) => {
        state.isAuthenticated = action.payload
      },
    setIsChanged: (state, action) => {
        state.isChanged = action.payload
      },
  },
});

export const {adminAuthToken, setIsAuthenticated, setIsChanged} = adminAuthSlice.actions;
export default adminAuthSlice.reducer;
