import { combineReducers, configureStore } from "@reduxjs/toolkit";
import expertReducer from "./ExpertSignup/expertSlice";
import adminReducer from "./AdminData/adminSlice";
import learnerReducer from "./learnerSlice";
import { persistReducer } from "redux-persist";
import AdminAuthReducer from "./AdminAuth/AdminAuthReducer";
import storageSession from "redux-persist/lib/storage/session";

const persistConfig = {
  key: "root",
  version: 1,
  storage: storageSession,
};

const reducer = combineReducers({
  adminAuth: AdminAuthReducer,
  expert: expertReducer,
  admin: adminReducer,
  learner: learnerReducer,
});
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export default store;
