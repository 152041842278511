import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import Skeleton from "./Component/Skeleton/Skeleton";

const HomeLayout = lazy(() => import("./Component/HomeLayout/HomeLayout"));
const CorporateOnboard = lazy(() =>
  import("./Component/CorporateOnboard/CorporateOnboard")
);
const LearnerLayout = lazy(() =>
  import("./Component/LearnerOnboard/LearnerLayout")
);
const LearnerLocation = lazy(() =>
  import("./Component/LearnerOnboard/LearnerLocation")
);
const LearnerTiming = lazy(() =>
  import("./Component/LearnerOnboard/LearnerTiming")
);
const LearnerFrequency = lazy(() =>
  import("./Component/LearnerOnboard/LearnerFrequency")
);
const LearnerMode = lazy(() =>
  import("./Component/LearnerOnboard/LearnerMode")
);
const LearnerGoal = lazy(() =>
  import("./Component/LearnerOnboard/LearnerGoal")
);
const LearnerExperts = lazy(() =>
  import("./Component/LearnerOnboard/LearnerExperts")
);
const LearnerPersonal = lazy(() =>
  import("./Component/LearnerOnboard/LearnerPersonal")
);
const LearnerThanks = lazy(() =>
  import("./Component/LearnerOnboard/LearnerThanks")
);
const LearnerHome = lazy(() => import("./Component/LearnerHome/LearnerHome"));
const LearnerProfile = lazy(() => import("./Component/LearnerProfile/LearnerProfile"));
const NotFound = lazy(() => import("./Component/NotFound/NotFound"));
const HomePage = lazy(() => import("./Component/HomePage/HomePage"));
const ExpertSignup = lazy(() => import("./Component/Signup/ExpertSignup"));
const Login = lazy(() => import("./Component/Login/Login"));
const AdminLogin = lazy(() => import("./Component/AdminLogin/AdminLogin"));
const Privacy = lazy(() => import("./Component/policies/Privacy"));
const CookiesPolicy = lazy(() => import("./Component/policies/CookiesPolicy"));
const TermsAndConditions = lazy(() =>
  import("./Component/policies/TermsAndConditions")
);
const AdminLayout = lazy(() => import("./Component/AdminLayout/AdminLayout"));
const AdminDashboard = lazy(() =>
  import("./Component/AdminSections/AdminDashboard")
);
const AdminExperts = lazy(() =>
  import("./Component/AdminSections/AdminExperts")
);
const AdminLeads = lazy(() => import("./Component/AdminSections/AdminLeads"));
const AdminLearners = lazy(() =>
  import("./Component/AdminSections/AdminLearners")
);
const AdminChats = lazy(() => import("./Component/AdminSections/AdminChats"));
const AdminEvents = lazy(() => import("./Component/AdminSections/AdminEvents"));
const AdminNotifications = lazy(() =>
  import("./Component/AdminSections/AdminNotifications")
);
const AboutUsScreen = lazy(() =>
  import("./Component/AboutUsScreen/AboutUsScreen")
);
const ExpertHome = lazy(() => import("./Component/ExpertHome/ExpertHome"));
const ExpertProfile = lazy(() =>
  import("./Component/ExpertProfile/ExpertProfile")
);
const HowItWorks = lazy(() => import("./Component/HowItWorks/HowItWorks"));

function App() {
  const isRegistered = useSelector((state) => state.expert.isRegistered);
  const { isLoggedIn } = useSelector((state) => state.learner.llogin);
  const customToastStyle = {
    fontSize: "1.3rem",
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: customToastStyle,
        }}
      />
      <Suspense fallback={<Skeleton />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/expert-signup" element={<ExpertSignup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/llogin" element={<Login />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/home" element={<HomeLayout />}>
            <Route path="aboutus" element={<AboutUsScreen />} />
            <Route path="howitworks" element={<HowItWorks />} />
            {isRegistered ? (
              <>
                <Route path="expert-dashboard" element={<ExpertHome />} />
                <Route path="expert-profile" element={<ExpertProfile />} />
              </>
            ) : (
              <Route path="*" element={<Navigate to="/" replace />} />
            )}
            {isLoggedIn && (
              <>
                <Route path="learner-dashboard" element={<LearnerHome />} />
                <Route path="learner-profile" element={<LearnerProfile />} />
              </>
            )}
          </Route>
          <Route path="/onboardc" element={<CorporateOnboard />} />
          <Route path="/onboardl" element={<LearnerLayout />}>
            <Route path="location" element={<LearnerLocation />} />
            <Route path="timing" element={<LearnerTiming />} />
            <Route path="frequency" element={<LearnerFrequency />} />
            <Route path="mode" element={<LearnerMode />} />
            <Route path="goal" element={<LearnerGoal />} />
            <Route path="experts" element={<LearnerExperts />} />
            <Route path="personal" element={<LearnerPersonal />} />
            <Route path="thank-you" element={<LearnerThanks />} />
          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="experts" element={<AdminExperts />} />
            <Route path="leads" element={<AdminLeads />} />
            <Route path="learners" element={<AdminLearners />} />
            <Route path="chats" element={<AdminChats />} />
            <Route path="events" element={<AdminEvents />} />
            <Route path="notifications" element={<AdminNotifications />} />
          </Route>
          <Route path="*" element={<NotFound />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
